<template>
  <div class="flex flex-col h-screen">
    <!-- loading遮罩 -->
    <van-overlay :show="pageLoading" class="flex items-center justify-center">
      <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
    </van-overlay>

    <!-- 标题栏 -->
    <div class="h-[44px] flex items-center  justify-center px-4"
         style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);">
      <span class="text-white text-lg">我的</span>
    </div>

    <!-- 内容区域 -->
    <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        head-height="50"
        loading-text="加载中..."
        pulling-text=""
        loosing-text=""
        success-text=""
        :loading-icon="loadingIcon"
        class="custom-loading flex-1 overflow-y-auto"
    >
      <div class="pb-[50px]">
        <div class="h-full w-full bg-windows">
          <div class="overflow-hidden w-full bg-page">
            <div class="page-content-2 pb-safe relative">

              <div class="user-info-container">
                <div class="flex items-center justify-between">
                  <!-- 左侧头像和信息 -->
                  <div class="flex items-center">
                    <img class="w-[44px] h-[44px] rounded-full"
                         @click="jumpToPage('userInfo')"
                         :src="userInfo.head_img?userInfo.head_img: require('@/assets/img/d-avatar.png')"
                         alt="">
                    <div class="ml-3">
                      <p class="text-[#313131] text-base font-medium mb-1">
                        {{ userInfo.real_name || '未实名' }}
                      </p>
                      <p class="phone-number">{{ userInfo.name|maskPhoneNumber }}</p>
                      <div class="invite-code">
                        <span class="label">邀请码：</span>
                        <span class="code">{{ userInfo.code }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- 右侧按钮 -->
                  <div class="flex flex-col items-end">
                    <img src="@/assets/img/profile/btn_sign.png"
                         class="w-[60px] h-[22px]"
                         @click="handleSignIn"
                         alt=""/>
                    <div class="flex items-center mt-2 cursor-pointer copy-link" @click="jumpToPage('downloadApp')">
                      <span>邀请好友</span>
                      <van-icon name="arrow" size="12"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="asset-block">
                <!-- 总资产 -->
                <div class="total-asset">
                  <div class="flex items-center">
                    <span class="text-[14px] text-[#333333]">总资产</span>
                    <van-icon name="eye-o" class="ml-2" size="16" color="#333333"/>
                  </div>
                  <div class="amount-text">{{ walletInfo.balance.amount  }}<span
                      class="text-[14px] ml-1">元</span></div>
                </div>

                <!-- 分割线 -->
                <div class="divider"></div>

                <!-- 再生资源投资金 -->
                <div class="resource-asset">
                  <div class="flex justify-between">
                    <div class="flex items-center">
                      <img src="@/assets/img/profile/icon_total.png" class="w-[20px] h-[20px]"/>
                      <div class="ml-2">
                        <div class="fund-amount">{{ walletInfo.reborn_invite_award.amount  }}</div>
                        <div class="fund-title mt-1">再生资源投资金(元)</div>
                      </div>
                    </div>
                    <div class="flex flex-col items-end">
                      <button class="action-btn withdraw"
                              @click="jumpToWithdraw(walletInfo.reborn_invite_award)">提现
                      </button>
                      <button class="action-btn detail mt-2" @click="jumpToWithdrawDetail(walletInfo.reborn_invite_award)">明细</button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 环保金余额容器 -->
              <div class="env-asset">
                <div class="flex justify-between">
                  <div class="flex items-center">
                    <img src="@/assets/img/profile/icon_wallet.png" class="w-[20px] h-[20px]"/>
                    <div class="ml-2">
                      <div class="fund-amount">{{ walletInfo.invite_award.amount }}</div>
                      <div class="fund-title mt-1">环保金余额(元)</div>
                    </div>
                  </div>
                  <div class="flex flex-col items-end">
                    <button class="action-btn withdraw"
                            @click="jumpToWithdraw(walletInfo.invite_award)">提现
                    </button>
                    <button class="action-btn detail mt-2" @click="jumpToWithdrawDetail(walletInfo.invite_award)">明细</button>
                  </div>
                </div>
              </div>
              <div class="env-wallet" @click="jumpToPage('envWallet')">
                <div class="flex items-center justify-center">
                  <img src="@/assets/img/profile/icon_wallet.png" class="wallet-icon"/>
                  <span class="wallet-title">资环钱包</span>
                </div>
              </div>
              <div class="env-wallet" @click="jumpToPage('specialSubsidies')">
                <div class="flex items-center justify-center">
                  <img src="@/assets/img/profile/icon_wallet.png" class="wallet-icon"/>
                  <span class="wallet-title">困难群众补贴</span>
                </div>
              </div>
              <div class="activity-img" @click="jumpToPage('userInvite')">
                <img src="@/assets/img/profile/activity.png" class="w-full rounded-[10px]"/>
              </div>
              <div class="common-tools">
                <div class="title">常用功能</div>
                <div class="grid grid-cols-3 gap-3">
                  <div class="tool-item" v-for="item in commonTools" :key="item.id" @click="jumpToPage(item.route)">
                    <img :src="item.icon" class="tool-icon"/>
                    <span class="tool-text">{{ item.name }}</span>
                  </div>
                </div>
              </div>
              <div class="other-tools">
                <div class="title-bg">
                  <div class="title">其他功能</div>
                </div>
                <div class="other-tools-list">
                  <div class="tool-item" v-for="item in otherTools" :key="item.id"
                       @click="item.route === 'logout' ? onLoginOut() : jumpToPage(item.route)">
                    <div class="flex items-center">
                      <img :src="item.icon" class="other-tool-icon"/>
                      <span class="other-tool-text">{{ item.name }}</span>
                    </div>
                    <van-icon name="arrow" class="text-[#999999]" size="14"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import {WithdrawalType} from "@/config";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import {PullRefresh, Overlay, Loading} from "vant";

export default {
  name: "index",
  components: {
    [PullRefresh.name]: PullRefresh,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
  },
  computed: {
    ...mapState("user", ["userInfo", "userWallet"]),
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    },
    displayName: function () {
      if (this.userInfo.real_name) {
        return this.userInfo.real_name;
      } else if (this.userInfo.name) {
        return this.userInfo.name;
      } else {
        return "未设置";
      }
    },
  },
  data() {
    return {
      invitaCode: "",
      shareUrl: "",
      shouldGenerateQRCode: false,
      WithdrawalType,
      walletInfo: {
        balance: {
          amount: '0.00',
          title: '总资产',
          type: 'balance'
        },
        reborn_invite_award: {
          amount: '0.00',
          title: '再生资源投资金',
          type: 'reborn_invite_award'
        },
        invite_award: {
          amount: '0.00',
          title: '环保金',
          type: 'invite_award',
          daily_limit: '0.00'
        }
      },
      recommendRecords: [],
      refreshing: false,
      loadingIcon: 'loading',
      pageLoading: true,
      // 常用功能数据
      commonTools: [
        {
          id: 1,
          name: '认证中心',
          icon: require('@/assets/img/profile/icon_auth.png'),
          route: 'realNameAuth'
        },
        {
          id: 2,
          name: '客服中心',
          icon: require('@/assets/img/profile/icon_service.png'),
          route: 'customService'
        },
        {
          id: 3,
          name: '银行绑定',
          icon: require('@/assets/img/profile/icon_bank.png'),
          route: 'bindBankCard'
        },
        {
          id: 4,
          name: '投资中心',
          icon: require('@/assets/img/profile/icon_invest.png'),
          route: 'envWallet'
        },
        {
          id: 5,
          name: '提现记录',
          icon: require('@/assets/img/profile/icon_withdraw.png'),
          route: 'withdrawRecord'
        },
        {
          id: 6,
          name: '团队中心',
          icon: require('@/assets/img/profile/icon_team.png'),
          route: 'team'
        }
      ],

      // 其他功能数据
      otherTools: [
        {
          id: 1,
          name: '修改密码',
          icon: require('@/assets/img/profile/icon_mdf_pwd.png'),
          route: 'modifyPwd'
        },
        {
          id: 2,
          name: '收货地址',
          icon: require('@/assets/img/profile/icon_address.png'),
          route: 'address'
        },
        // {
        //   id: 3,
        //   name: '团队奖提现记录',
        //   icon: require('@/assets/img/profile/icon_reward.png'),
        //   route: 'teamReward'
        // },
        {
          id: 4,
          name: 'APP下载',
          icon: require('@/assets/img/profile/icon_download.png'),
          route: 'downloadApp'
        },
        // {
        //   id: 5,
        //   name: '关于中国资源循环集团',
        //   icon: require('@/assets/img/profile/icon_about.png'),
        //   route: 'about'
        // },
        {
          id: 6,
          name: '退出登录',
          icon: require('@/assets/img/profile/icon_logout.png'),
          route: 'logout'
        }
      ]
    };
  },
  mounted() {
    this.getUserInfo();
    this.getSystemInfo();
    this.getIdentifyInfo();
    this.getUserWallet();
  },
  filters: {
    toIntegerOrZero(value) {
      if (value !== undefined) {
        return Math.floor(value);
      } else {
        return 0;
      }
    },
    maskPhoneNumber(phoneNumber) {
      const maskedNumber = phoneNumber?.replace(/(\d{3})\d{4}(\d{4})/, '\$1****\$2');
      return maskedNumber;
    },
    formatDecimal(value) {
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      return parseFloat(value).toFixed(0);
    },
    formatAmount(value) {
      if (!value || isNaN(value)) {
        return '0';
      }
      const num = Number(value);
      if (Number.isInteger(num)) {
        return num.toString();
      }
      return num.toFixed(2);
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo", "getIdentifyInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    jumpToWithdraw(data) {
      // Check if it's environmental points withdrawal
      if (data.type === 'invite_award') {
        this.$dialog.alert({
          title: '提示',
          message: '请完成资环钱包提现',
        });
        return;
      }
      // Navigate to investment center for renewable resource investment points
      // if (data.type === 'reborn_invite_award') {
      //   this.$router.push({name: 'investCentral'});
      //   return;
      // }
      this.$router.push({
        name: "withdrawal",
        query: data,
      });
    },
    jumpToWithdrawDetail(data) {
      this.$router.push({
        name: "fundDetail",
        query: {
          type:data.type,
        },
      });
    },
    jumpToPage(name) {
      if (name == 'downloadApp') {
        this.$router.push({name: 'inviteUser', query: {code: this.userInfo.code}});
        return
      }
      if (name == 'customService') {
        window.location.href = this.systemInfo.customerService;
        return
      }
      if (name == 'teamReward') {
        this.$router.push({name: 'withdrawRecord', query: {type: 'income_wallet'}});
        return
      }
      this.$router.push({name});
    },
    getUserWallet() {
      axios.get(api.userWallet)
        .then((res) => {
          const coinList = res.data.coinList;
          const walletData = {
            balance: {
              amount: '0.00',
              title: '总资产',
              type: 'balance'
            },
            reborn_invite_award: {
              amount: '0.00',
              title: '再生资源投资金',
              type: 'reborn_invite_award'
            },
            invite_award: {
              amount: '0.00',
              title: '环保金',
              type: 'invite_award',
            }
          }

          // 更新数据
          coinList.forEach(coin => {
            if (walletData[coin.name_en]) {
              walletData[coin.name_en] = {
                amount: coin.num || '0.00',
                title: coin.name ,
                type: coin.name_en,
              }
            }
            // 找到day_withdraw数据时更新invite_award的daily_limit
            if (coin.name_en === 'day_withdraw') {
              walletData.invite_award.daily_limit = coin.num || '0.00';
            }
          })

          // 计算总资产 = 再生资源投资金 + 环保金余额
          const rebornAmount = parseFloat(walletData.reborn_invite_award.amount) || 0;
          const inviteAmount = parseFloat(walletData.invite_award.amount) || 0;
          walletData.balance.amount = (rebornAmount + inviteAmount).toFixed(2);

          this.walletInfo = walletData
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.pageLoading = false
        })
    },
    onLoginOut() {
      this.$dialog.confirm({
        title: '提示',
        message: '确定要退出登录吗？',
      })
          .then(() => {
            this.$store.commit("tabs/ACCOUNT_LOGOUT_FAILURE", true);
          })
          .catch(() => {
          });
    },

    loadMore() {
      axios
          .get(api.receiveList)
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.recommendRecords = res.data.map(item => {
                return item.recom_num
              })
            }
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            err.msg && this.$toast(err.msg);
          });
    },
    onCopyText() {
      console.log('复制邀请链接', this.userInfo);
      const text = `${this.$config.WEB_URL}/register?code=${this.userInfo.code}`;
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功！')
    },
    onRefresh() {
      this.refreshing = true;
      this.pageLoading = true;
      Promise.all([
        this.getUserInfo(),
        this.getSystemInfo(),
        this.getUserWallet()
      ]).finally(() => {
        this.refreshing = false;
        this.pageLoading = false;
      });
    },
    handleSignIn() {
      axios.post(api.signIn)
          .then((res) => {
            this.$toast('签到成功');
            this.getUserInfo();
            this.getUserWallet();
          })
          .catch((err) => {
            if (err.code === 430) {
              this.$dialog.alert({
                message: err.msg || '请先完成实名认证',
                confirmButtonText: '去认证',
                confirmButtonColor: '#F34947',
              }).then(() => {
                this.$router.push({name: 'realNameAuth'});
              });
            } else {
              err.msg && this.$toast(err.msg);
            }
          });
    },
  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.page-content /deep/ .van-grid-item__text {
  font-size: 14px
}

.item-btn {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333435;
  margin: 20px auto auto;
}

.copy-link-btn {
  background: #FFE6C8;
  border-radius: 5px;
  font-family: MiSans;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #F14B47;
  padding: 5px 10px;
}

.bg-info {
  padding: 0 10px;
}

.kf-container {
  width: 61px;
  height: 78px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.user-info-container {
  margin: 12px 16px;
  padding: 16px;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.info-text {
  font-size: 14px;
  color: #666;
}

.copy-btn {
  background: #FFE6C8;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 14px;
  color: #F14B47;
}

.sign-btn {
  height: 26px;
}

.invite-img {
  width: calc(100% - 32px);
  margin: 16px;
  border-radius: 10px;
}

// 加下拉刷新样式
.custom-loading {
  -webkit-overflow-scrolling: touch;

  :deep(.van-pull-refresh__head) {
    .van-loading {
      .van-loading__spinner {
        color: #fff !important;
      }

      .van-loading__text {
        color: #fff !important;
      }
    }

    .van-pull-refresh__text {
      color: #fff !important;
    }
  }
}

.amount-text {
  color: #D1645A;
  font-family: "PingFang SC";
  font-size: 15.89px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
}

.asset-block {
  margin: 12px 16px;
  background: #FFFFFF;
  border-radius: 8px;
  overflow: hidden;
}

.total-asset {
  padding: 16px;
}

.divider {
  height: 1px;
  background: #F5F5F5;
  margin: 0 16px;
}

.resource-asset {
  padding: 16px;
}

.env-asset {
  margin: 12px 16px 0;
  padding: 16px;
  background: #FFFFFF;
  border-radius: 8px;
}

.action-btn {
  display: flex;
  width: 51px;
  height: 21px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  border-radius: 4px;
  font-size: 12px;
  color: #FFFFFF;

  &.withdraw {
    background: #FA4949;
  }

  &.detail {
    background: #BA8E8E;
  }
}

.fund-title {
  color: #B59F9D;
  font-family: "PingFang SC";
  font-size: 13.889px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-edge: cap;
  leading-trim: both;
}

.fund-amount {
  color: #333;
  font-family: "Yuanti SC";
  font-size: 21.528px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-edge: cap;
  leading-trim: both;
}

.copy-link {
  span {
    color: #563634;
    font-family: "PingFang SC";
    font-size: 11.111px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .van-icon {
    margin-left: 4px;
    color: #563634;
  }
}

.invite-code {
  .label {
    color: #B59F9D;
    font-family: "PingFang SC";
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-edge: cap;
    leading-trim: both;
  }

  .code {
    color: #FF4242;
    font-family: "PingFang SC";
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-edge: cap;
    leading-trim: both;
  }
}

.phone-number {
  color: #333;
  font-family: "Yuanti SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.common-tools {
  margin: 0 16px 12px;
  padding: 16px;
  background: #FFFFFF;
  border-radius: 8px;

  .title {
    font-family: "PingFang SC";
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 16px;
  }

  .tool-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .tool-icon {
      width: 52px;
      height: 52px;
      flex-shrink: 0;
    }

    .tool-text {
      color: #999;
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.other-tools {
  background: #FFFFFF;

  .title-bg {
    background: #ECF1F9;
    padding: 10px;

    .title {
      font-family: "PingFang SC";
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }

  .other-tools-list {
    padding: 0 16px;

    .tool-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      cursor: pointer;
      border-bottom: 1px solid #F5F5F5;

      &:last-child {
        border-bottom: none;
      }

      .other-tool-icon {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }

      .other-tool-text {
        color: #2A3563;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.24px;
        margin-left: 12px;
      }
    }
  }
}

.env-wallet {
  margin: 12px 16px 0;
  padding: 16px;
  background: #FFFFFF;
  border-radius: 8px;

  .wallet-icon {
    width: 20px;
    height: 20px;
  }

  .wallet-title {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    margin-left: 8px;
  }
}

</style>
