<template>
  <div class="flex flex-col h-screen">
    <!-- loading遮罩 -->
    <van-overlay :show="pageLoading" class="flex items-center justify-center">
      <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
    </van-overlay>

    <!-- 标题栏 -->
    <div class="h-[44px] flex items-center justify-center px-4" style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);">
      <span class="text-white text-lg">环保金</span>
    </div>

    <!-- 内容区域 -->
    <div class="flex-1 overflow-y-auto bg-[#B1130F] pb-16">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        head-height="50"
        loading-text="加载中..."
        pulling-text=""
        loosing-text=""
        success-text=""
        :loading-icon="loadingIcon"
        class="custom-loading"
      >
        <!-- 地图背景 -->
        <div class="py-3">
          <div class="h-[200px]" ref="mapChart"></div>
        </div>

        <!-- 选择区域 -->
        <div class="px-4 py-3">
          <div class="text-white text-lg mb-2">所在地区</div>
          <div class="bg-white rounded-[10px] p-3">
            <div class="border border-[#ECA9A9] rounded-[10px] p-3">
              <div class="flex justify-between items-center">
                <div class="flex-1">
                  <div class="text-[14px] text-[#484848] font-normal mb-2 text-center" style="font-family: 'PingFang SC'">省份</div>
                  <div class="relative w-full h-[32px] bg-[#F2F2F2] rounded-[3px] flex items-center justify-center text-[13px] text-[#484848]"
                       @click="showProvincePicker = true">
                    <span>{{ selectedProvince || '请选择' }}</span>
                    <van-icon name="arrow-down" class="absolute right-2" size="14" color="#484848" />
                  </div>
                </div>
              </div>
              <div class="flex gap-3 mt-4">
                <button
                  class="flex-1 h-[40px] border rounded text-base font-medium"
                  :class="[
                    isUserProvinceActivated
                      ? 'border-gray-300 text-gray-300 cursor-not-allowed'
                      : 'border-[#F34947] text-[#F34947]'
                  ]"
                  @click="activateProvince"
                  :disabled="isUserProvinceActivated"
                >
                  {{ isUserProvinceActivated ? '已激活' : '激活' }}
                </button>
                <button class="flex-1 h-[40px] bg-[#F34947] text-white rounded text-base font-medium" @click="jumpToProvince">
                  激活省份
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Picker组件 -->
        <van-popup v-model="showProvincePicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="provinces"
            @confirm="onProvinceConfirm"
            @cancel="showProvincePicker = false"
            title="选择省份"
          />
        </van-popup>

        <!-- 列表区域 -->
        <div class="px-4">
          <div v-for="(item, index) in list" :key="index"
               class="bg-white rounded-[10px] p-4 mb-3">
            <div class="text-[#484848] text-[14px] font-normal text-center mb-3" style="font-family: 'PingFang SC'">
              {{ item.describe }}
            </div>
            <div class="flex justify-between items-start">
              <div class="flex-1">
                <div class="flex items-center">
                  <span class="text-[#484848] text-[14px] font-normal" style="font-family: 'PingFang SC'">再生资源投资金</span>
                  <div class="flex items-center ml-2">
                    <span class="text-[15px] font-normal leading-[18px]"
                          style="font-family: 'PingFang SC'; background: linear-gradient(180deg, #FF5A46 0%, #F62836 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;">
                      {{ item.gift_coin_num }}
                    </span>
                    <span class="text-[13px] font-normal leading-[18px] ml-1"
                          style="font-family: 'PingFang SC'; background: linear-gradient(180deg, #FF5A46 0%, #F62836 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;">
                      元
                    </span>
                  </div>
                </div>
                <div class="flex items-center mt-2">
                  <span class="text-[#484848] text-[14px] font-normal" style="font-family: 'PingFang SC'">环保金</span>
                  <div class="flex items-center ml-2">
                    <span class="text-[15px] font-normal leading-[18px]"
                          style="font-family: 'PingFang SC'; background: linear-gradient(180deg, #FF5A46 0%, #F62836 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;">
                      {{ item.invite_award_num }}
                    </span>
                    <span class="text-[13px] font-normal leading-[18px] ml-1"
                          style="font-family: 'PingFang SC'; background: linear-gradient(180deg, #FF5A46 0%, #F62836 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;">
                      元
                    </span>
                  </div>
                </div>
              </div>
              <img
                :src="getStatusIcon(item)"
                class="w-[48px] h-[48px] flex-shrink-0"
                @click="handleImageClick(item)"
              />
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import chinaJson from '@/assets/china.json'
import {Icon, Loading, Overlay, Picker, Popup, PullRefresh} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {mapState, mapActions} from "vuex";

export default {
  name: "index",
  components: {
    [PullRefresh.name]: PullRefresh,  // 正确注册 PullRefresh 组件
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
  },
  data() {
    return {
      list: [],
      loading: false,
      refreshing: false,
      showProvincePicker: false,
      selectedProvince: '',
      provinces: [],
      loadingIcon: 'loading',
      mapChart: null,
      mapData: [],
      pageLoading: true,
      isUserProvinceActivated: false,
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    },
  },
  async mounted() {
    echarts.registerMap('china', chinaJson)
    await this.getUserInfo()
    await this.loadMore()
    this.initMap()
  },

  methods: {
    ...mapActions("user", ["getUserInfo"]),

    onRefresh() {
      this.refreshData()
    },
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    async loadMore() {
      try {
        const response = await axios.get(api.receiveList, { params: { type: 1 }})
        if (response.data?.inviteConfigList) {
          this.list = response.data.inviteConfigList;
        }

        if(response.data?.provinceList) {
          // 获取所有省份
          const allProvinces = chinaJson.features.map(feature => feature.properties.name);

          // 检查用户省份是否已激活
          const userProvince = this.userInfo?.province || '';
          this.isUserProvinceActivated = response.data.provinceList.includes(userProvince);


          // 如果用户省份未激活，设置为默认选中
          if (userProvince && !this.isUserProvinceActivated) {
            this.selectedProvince = userProvince;
          }

          // 更新可选择的省份列表 - 过滤掉已激活的省份
          this.provinces = allProvinces.filter(name => {
            return !response.data.provinceList.includes(name);
          });

          // 更新地图数据
          this.mapData = allProvinces.map(name => {
            const isActive = response.data.provinceList.includes(name);

            return {
              name,
              value: 0,
              itemStyle: {
                normal: {
                  areaColor: isActive ? '#472929' : '#2EEA60',
                  borderColor: '#fff',
                  borderWidth: 1
                }
              }
            }
          });

          if(this.mapChart) {
            this.mapChart.setOption({
              series: [{
                data: this.mapData
              }]
            });
          }
        }
      } catch(err) {
        err.msg && this.$toast(err.msg);
      } finally {
        this.loading = false;
        this.refreshing = false;
        this.pageLoading = false;
      }
    },
    getStatusIcon(item) {
      switch(item.status) {
        case 1: // 未达标
          return require('@/assets/img/zhzj/wlq.png')
        case 2: // 已达标
          return require('@/assets/img/zhzj/wlq.png')
        case 3: // 已领取
          return require('@/assets/img/zhzj/ylq.png')
        default:
          return require('@/assets/img/zhzj/wdb.png')
      }
    },
    onProvinceConfirm(value) {
      this.selectedProvince = value
      this.showProvincePicker = false
    },
    initMap() {
      if (!this.$refs.mapChart) return;

      this.mapChart = echarts.init(this.$refs.mapChart, {
        backgroundColor: 'transparent'
      })

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}'
        },
        series: [{
          name: '中国',
          type: 'map',
          map: 'china',
          roam: false,
          aspectScale: 0.90,
          layoutCenter: ['50%', '74%'],
          layoutSize: '160%',
          data: this.mapData,
          emphasis: {
            label: {
              show: true
            },
            itemStyle: {
              areaColor: '#472929'
            }
          }
        }]
      }

      this.mapChart.setOption(option)
    },
    async activateProvince() {
      if (this.isUserProvinceActivated) {
        return; // 如果已激活，直接返回
      }

      if (!this.selectedProvince) {
        this.$toast('请选择省份');
        return;
      }

      const loading = this.$toast.loading({
        message: '激活中...',
        forbidClick: true,
      });

      try {
        await axios.post(api.activeInvite, {
          province: this.selectedProvince
        });

        this.$toast.success('激活成功');
        this.selectedProvince = '';
        this.showProvincePicker = false;

        // 重新加载数据
        await this.loadMore();

      } catch (err) {
        if (err.code === 430) {
          await this.$dialog.alert({
            message: err.msg || '请先完成实名认证',
            confirmButtonText: '去认证',
            confirmButtonColor: '#F34947',
          });
          this.$router.push({name: 'realNameAuth'});
        } else {
          await this.$dialog.alert({
            message: err.msg || '激活失败',
            confirmButtonText: '确定',
            confirmButtonColor: '#F34947',
          });
        }
      } finally {
        loading.clear();
      }
    },
    jumpToProvince() {
      this.$router.push({
        name: 'activateProvince',
      })
    },
    async handleImageClick(item) {
      // 未达标状态
      if (item.status === 1) {
        if (!this.userInfo?.is_identify) {
          this.$dialog.alert({
            message: '请先完成实名认证',
            confirmButtonText: '去认证',
            confirmButtonColor: '#F34947',
          }).then(() => {
            this.$router.push({name: 'realNameAuth'});
          });
          return;
        }

        this.$dialog.alert({
          message: `需要邀请${item.invite_identity_user}位好友`,
          confirmButtonText: '确定',
          confirmButtonColor: '#F34947',
        });
        return;
      }

      // 待领取状态
      if (item.status === 2) {
        const loading = this.$toast.loading({
          message: '领取中...',
          forbidClick: true,
        });

        try {
          await axios.post(api.receiveAward, {
            id: item.id
          });

          this.$dialog.alert({
            message: '领取成功',
            confirmButtonText: '确定',
            confirmButtonColor: '#F34947',
          });

          // 刷新页面数据
          await this.loadMore();

        } catch (err) {
          this.$dialog.alert({
            message: err.msg || '领取失败',
            confirmButtonText: '确定',
            confirmButtonColor: '#F34947',
          });
        } finally {
          loading.clear();
        }
      }
    },
  },
  beforeDestroy() {
    // 销毁图表实例
    if(this.mapChart) {
      this.mapChart.dispose()
    }
  }
};
</script>

<style scoped lang="less">
.top-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.custom-loading {
  :deep(.van-pull-refresh__head) {
    .van-loading {
      .van-loading__spinner {
        color: #fff !important;
      }
      .van-loading__text {
        color: #fff !important;
      }
    }
    .van-pull-refresh__text {
      color: #fff !important;
    }
  }
}

.map-container {
  width: 100%;
  height: 200px; // 减小高度
  border-radius: 10px;
}
</style>
