<template>
  <div class="h-full w-full flex flex-col">
    <!-- 标题栏 -->
    <div class="h-[44px] flex items-center px-4 fixed top-0 left-0 right-0 z-20"
         style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);">
      <van-icon name="arrow-left" class="text-white" @click="onClickLeft" />
      <span class="text-white text-lg flex-1 text-center mr-[24px]">团队中心</span>
    </div>

    <!-- 添加标题栏占位 -->
    <div class="h-[44px]"></div>

    <!-- 内容区域 -->
    <div class="flex-1 overflow-y-auto" style="background: linear-gradient(180deg, #FFB9B8 40%, #FFF 100%);">
      <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
        <List v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMore">
          <div class="p-4">
            <div class="bg-white rounded-[10px] p-4">
              <!-- 内容标题 -->
              <div class="flex items-center mb-4">
                <div class="flex flex-col items-center">
                  <span class="text-[#F23D30]" style="font-family: 'Abhaya Libre Medium'; font-size: 16px; font-weight: 500; letter-spacing: -0.5px;">
                    团队中心
                  </span>
                  <!-- 下划线装饰 -->
                  <div class="w-[20px] h-[3px] mt-1 rounded-[8px]" style="background: #F23D30;"></div>
                </div>
              </div>

              <!-- 二维码区域 -->
              <div class="bg-cover bg-center bg-no-repeat h-[200px] w-full rounded-[10px] relative"
                   :style="{ backgroundImage: `url(${require('@/assets/img/team/bg_team_top.png')})` }">
                <!-- 二维码内容区域 - 修改定位 -->
                <div class="absolute left-8" style="top: 20%;">
                  <div class="flex flex-col">
                    <!-- 二维码 -->
                    <div id="qrcode" class="mb-2"></div>

                    <!-- 邀请码标题 -->
                    <span class="text-[#585757] font-['PingFang SC'] text-xs font-normal leading-6">
                      邀请码
                    </span>

                    <!-- 邀请码 -->
                    <span class="text-[#292929] font-['PingFang SC'] text-[18px] font-normal leading-6">
                      {{ inviteCode }}
                    </span>

                    <!-- 复制区域 -->
                    <div class="flex items-center mt-1 cursor-pointer" @click="onCopyText(inviteUrl)">
                      <img src="@/assets/img/team/copy.png" class="w-4 h-4 mr-1" alt="copy"/>
                      <span class="text-[#585757] font-['PingFang SC'] text-[10px] font-normal leading-6">
                        复制邀请链接
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 修改统计数据部分 -->
              <div class="flex justify-center items-center mt-4">
                <div class="flex justify-center items-center w-[317px] h-[89px] px-[25px] py-[3px] flex-shrink-0 rounded-[8px]"
                     style="background: #FF5757;">
                  <div class="flex justify-between w-full">
                    <div class="text-center">
                      <div class="text-white text-center font-[zihunxingmouhei_trial] text-[20px] font-normal leading-[28px]">
                        {{ childCount?.zhi_num || '0' }}
                      </div>
                      <div class="text-[#DFDFDF] font-['PingFang SC'] text-xs font-normal leading-[18px]">
                        直推人数
                      </div>
                    </div>
                    <div class="text-center">
                      <div class="text-white text-center font-[zihunxingmouhei_trial] text-[20px] font-normal leading-[28px]">
                        {{ childCount?.group_num || '0' }}
                      </div>
                      <div class="text-[#DFDFDF] font-['PingFang SC'] text-xs font-normal leading-[18px]">
                        团队总人数
                      </div>
                    </div>
                    <div class="text-center">
                      <div class="text-white text-center font-[zihunxingmouhei_trial] text-[20px] font-normal leading-[28px]">
                        {{ childCount?.totalAchievement || '0.00' }}
                      </div>
                      <div class="text-[#DFDFDF] font-['PingFang SC'] text-xs font-normal leading-[18px]">
                        总业绩(元)
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Tab列表 -->
              <van-tabs v-model="activeTab" @click="onClick" class="mt-4 custom-tabs">
                <van-tab v-for="tab in tabs" :key="tab.name" :title="tab.title" :name="tab.name">
                  <div class="min-h-[200px]">
                    <!-- 添加列表统计 -->
                    <div class="px-4 py-4 text-[#494949] font-['PingFang SC'] text-[14px]">
                      {{ tab.title }}({{ total || 0 }}人)
                    </div>

                    <!-- 首次加载中 -->
                    <div v-if="firstLoading" class="py-8 text-center">
                      <van-loading type="spinner" />
                    </div>
                    <!-- 无数据时显示空视图 -->
                    <van-empty
                      v-else-if="list.length === 0 && !loading"
                      class="custom-empty"
                      image="search"
                      description="暂无相关数据"
                    />
                    <!-- 有数据时显示列表 -->
                    <template v-else>
                      <div v-for="(item, index) in list" :key="index"
                           class="flex items-center p-4"
                           :class="{'border-b border-gray-200': index !== list.length - 1}">
                        <van-image
                            :radius="8"
                            width="50px"
                            height="50px"
                            :src="item.head_img || require('@/assets/img/d-avatar.png')"
                        />
                        <div class="ml-4 flex-1">
                          <div class="flex justify-between items-center">
                            <div class="flex items-center">
                              <span class="mr-2 font-['PingFang SC'] text-[14px] leading-6"
                                    :style="{ color: item.real_name !== '未实名' ? '#494949' : '#E86565' }">
                                {{ item.real_name }}
                              </span>
                              <span class="text-[#494949] font-['PingFang SC'] text-[13px] font-normal leading-6">
                                {{ item.username }}
                              </span>
                            </div>
                            <span class="text-[#8D8D8D] text-right font-['PingFang SC'] text-[11px] font-normal leading-6">
                              {{ item.created_at | timeFormat('YYYY/MM/DD') }}
                            </span>
                          </div>
                          <div class="text-[#494949] font-['PingFang SC'] text-[13px] font-normal leading-6">
                            {{ item.achievement || '0.00' }}元 (业绩)
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </van-tab>
              </van-tabs>
            </div>
          </div>
        </List>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import { mapActions, mapState } from "vuex";
import { Empty, Image as VanImage, List, NavBar, PullRefresh, Tab, Tabs } from "vant";
import QRCode from "qrcodejs2";

export default {
  name: "index",
  components: { NavBar, List, VanImage, Empty, PullRefresh, [Tab.name]: Tab, [Tabs.name]: Tabs },
  data() {
    return {
      count: 0,
      pageSize: 10,
      pageNo: 1,
      level:1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      childCount: {},
      activeTab: '0',
      tabs: [
        { title: '直推好友', name: '0' },
        { title: '二级好友', name: '1' },
        { title: '三级好友', name: '2' }
      ],
      inviteCode: "",
      inviteUrl: "",
      firstLoading: true,
      total: 0,
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return "我的团队";
    }
  },
  mounted() {
    this.getUserInfo().then(() => {
      if (this.userInfo && this.userInfo.code) {
        this.inviteCode = this.userInfo.code;
        this.inviteUrl = `${this.$config.WEB_URL}/register?code=${this.inviteCode}`;
        this.$nextTick(() => {
          this.generateQRCode();
        });
      }
    });
    this.loadMore();
  },
  watch: {
    userInfo: {
      handler(newVal, oldVal) {
        if (newVal.isCaptain !== oldVal?.isCaptain) {
          this.getTeamStatistics()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onClick(name) {
      this.level = parseInt(name) + 1;
      this.resetList();
    },
    resetList() {
      this.list = [];
      this.pageNo = 1;
      this.finished = false;
      this.loading = false;
      this.firstLoading = true;
      this.loadMore();
    },
    refreshData() {
      this.resetList();
      this.refreshing = false;
    },
    loadMore() {
      if(this.refreshing) {
        this.list = [];
      }

      this.loading = true;
      axios.get(api.teamList, {
        params: {
          level: this.level,
          page: this.pageNo,
          size: this.pageSize,
        }
      })
      .then((res) => {
        this.firstLoading = false;

        if(this.pageNo === 1) {
          this.list = res.data.list;
          this.childCount = res.data.userInfo || {};
        } else {
          this.list = [...this.list, ...res.data.list];
        }
        this.total = res.data.total;

        // 判断是否加载完成
        this.finished = this.list.length >= this.total;
        this.loading = false;
        if (this.list.length > 0) {
          this.pageNo++;
        }
      })
      .catch((err) => {
        this.firstLoading = false;
        this.loading = false;
        this.finished = true;
        err.msg && this.$toast(err.msg);
      });
    },
    generateQRCode() {
      const qrcodeContainer = document.getElementById("qrcode");
      if (qrcodeContainer && this.inviteUrl) {
        qrcodeContainer.innerHTML = '';
        new QRCode(qrcodeContainer, {
          text: this.inviteUrl,
          width: 62,
          height: 63,
          colorDark : "#000000",
          colorLight : "#ffffff",
          correctLevel : QRCode.CorrectLevel.H
        });
      }
    },
    onCopyText(text) {
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length);
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功！')
    }
  },
};
</script>

<style scoped lang="less">
.van-tabs__wrap {
  padding-top: 10px;
}

:deep(.van-nav-bar .van-icon) {
  color: #fff;
}

// 自定义按钮渐变样式
.gradient-btn {
  background: linear-gradient(90deg, #F23D30 0%, #FF6A57 48.44%, #F23D30 100%);
}

// 确保二维码图片居中
#qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 62px;
  height: 63px;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

// 自定义 tab 样式
.custom-tabs {
  :deep(.van-tabs__wrap) {
    background: white;
    padding-top: 10px;
  }

  :deep(.van-tab) {
    color: #313131;
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  :deep(.van-tabs__line) {
    width: 40.124px !important;
    height: 3px;
    background: #3C150D;
    bottom: 15px;
    border-radius: 1.5px;
  }

  :deep(.van-tabs__nav) {
    border: none;
  }

  :deep(.van-tabs__content) {
    background: white;
  }
}

// 自定义空视图样式
.custom-empty {
  padding: 60px 0;

  :deep(.van-empty__image) {
    width: 120px;
    height: 120px;
  }

  :deep(.van-empty__description) {
    color: #999;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }
}

// 修改 List 组件样式
:deep(.van-list) {
  min-height: 100%;
  overflow-y: visible;
}

// 修改 PullRefresh 组件样式
:deep(.van-pull-refresh) {
  min-height: 100%;
  overflow-y: visible;
}
</style>
