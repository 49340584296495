<template>
  <div class="flex flex-col h-screen">
    <!-- loading遮罩 -->
    <van-overlay :show="pageLoading" class="flex items-center justify-center">
      <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
    </van-overlay>

    <!-- 标题栏 -->
    <div class="h-[44px] flex items-center px-4 relative" style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);">
      <span class="text-white text-lg absolute left-1/2 -translate-x-1/2">投资中心</span>
    </div>

    <!-- 内容区域 -->
    <div class="flex-1 overflow-y-auto bg-[#F9948E] pb-16">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        class="custom-loading"
      >
        <!-- 投资中心卡片 -->
        <div class="px-4 pb-3 pt-6">
          <div class="bg-white rounded-lg p-4">
            <!-- 投资中心标题 -->
            <div class="text-center mb-3">
              <span class="text-[#A62727]"
                    style="font-family: 'PingFang SC'; font-size: 19px; font-weight: 400; line-height: 22px;">
                投资中心
              </span>
            </div>

            <!-- 三个指标 -->
            <div class="flex justify-between mt-2">
              <div class="text-center">
                <div class="text-[#A62727] text-xs mb-1">投资金额</div>
                <div class="text-[#313131]"
                     style="font-family: 'PingFang SC'; font-size: 20px; font-weight: 400; line-height: 22px;">
                  {{ investmentData.total_buy_amount }} 元
                </div>
              </div>
              <div class="text-center">
                <div class="text-[#A62727] text-xs mb-1">投资分红利率</div>
                <div class="text-[#313131]"
                     style="font-family: 'PingFang SC'; font-size: 20px; font-weight: 400; line-height: 22px;">
                  {{ investmentData.day_rate }}%
                </div>
              </div>
              <div class="text-center">
                <div class="text-[#A62727] text-xs mb-1">投资分红</div>
                <div class="text-[#313131]"
                     style="font-family: 'PingFang SC'; font-size: 20px; font-weight: 400; line-height: 22px;">
                  {{ investmentData.zihuan_wallet }} 元
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 功能按钮组 -->
        <div class="px-4 pb-3 grid grid-cols-2 gap-3">
          <div v-for="item in projectList"
               :key="item.id"
               class="bg-white rounded-lg p-4">
            <div class="text-[#182946] text-center mb-2"
                 style="font-family: 'PingFang SC'; font-size: 16px; font-weight: 400; line-height: 24px;">
              {{ item.describe }}
            </div>
            <template v-if="item.status !== 3">
              <div class="border border-[#B9C0CD] rounded-lg mb-2 overflow-hidden">
                <van-field
                  v-model="projectInputs[item.id]"
                  type="number"
                  placeholder="请输入"
                  center
                  :border="false"
                  input-align="center"
                  class="flex-1"
                />
              </div>
            </template>
            <template v-else>
              <div class="h-[58px] mb-2 rounded-lg bg-white flex items-center justify-center text-[#A62727]">
                已邀请 {{ item.invite_identity_user }} 人
              </div>
            </template>
            <button class="w-full h-[32px] rounded text-white"
                    :class="[
                      item.status === 3 ? 'bg-[#999999]' : 'bg-[#F52735]',
                      item.status === 3 ? 'cursor-not-allowed' : 'cursor-pointer'
                    ]"
                    @click="item.status !== 3 ? handleActivate(item) : null">
              {{ item.status === 1 || item.status === 4 ? '激活' : item.status === 2 ? '确定' : '已投资' }}
            </button>
          </div>
        </div>

        <!-- 功能按钮组后面添加 -->
        <div class="px-4 pb-3">
          <div class="bg-white rounded-lg p-4">
            <div class="text-center text-[#313131] text">
              投资无需缴纳任何费用
            </div>
            <div class="text-center text-[#313131] text mt-1">
              投资周期为24小时
            </div>
            <div class="text-center text-[#313131] text mt-1">
              投资次数: {{ investmentData.valid_num }}
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>

    <!-- 提交中遮罩 -->
    <van-overlay :show="submitting" class="flex items-center justify-center">
      <van-loading type="spinner" color="#fff" size="36" vertical>提交中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import {Field, Icon, PullRefresh, Overlay, Loading} from "vant";
import axios from '@/net/axios'
import api from '@/net/api'

export default {
  name: "investCentral",
  components: {
    [Icon.name]: Icon,
    [PullRefresh.name]: PullRefresh,
    [Field.name]: Field,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
  },
  data() {
    return {
      pageLoading: false,
      refreshing: false,
      submitting: false,
      investmentData: {
        valid_num: 0,
        total_num: 0,
        total_buy_amount: 0,
        day_rate: 0,
        zihuan_wallet: 0
      },
      projectList: [],
      projectInputs: {}
    }
  },

  created() {
    this.getInvestmentData()
    this.getProjectList()
  },

  methods: {
    async onRefresh() {
      try {
        this.refreshing = true
        await Promise.all([
          this.getInvestmentData(),
          this.getProjectList()
        ])
      } catch (error) {
        console.error('刷新失败:', error)
      } finally {
        this.refreshing = false
      }
    },

    async getProjectList() {
      try {
        const res = await axios.get(api.zihuanInviteList)
        if (res.data?.inviteConfigList) {
          this.projectList = res.data.inviteConfigList
          // 初始化输入值
          this.projectList.forEach(item => {
            this.$set(this.projectInputs, item.id, '')
          })
        }
      } catch (error) {
        console.error('获取项目列表失败:', error)
      }
    },

    async getInvestmentData() {
      try {
        const res = await axios.post(api.myInvestment)
        if (res.data?.data) {
          this.investmentData = res.data.data
        }
      } catch (error) {
        console.error('获取投资数据失败:', error)
      }
    },

    async handleActivate(project) {
        // 如果是激活状态(status === 1 或 4)，根据项目类型显示不同提示
        if (project.status === 1 || project.status === 4) {
          let message = '';

          // 根据项目名称判断类型
          if (project.describe.includes('以旧换新')) {
            message = `请邀请${project.invite_identity_user || 1}位好友`;
          } else if (project.describe.includes('有色金属')) {
            // 查找以旧换新项目
            const oldForNewProject = this.projectList.find(p => p.describe.includes('以旧换新'));
            if (!oldForNewProject || oldForNewProject.status !== 3) {
              message = '请先激活以旧换新';
            } else {
              message = `请邀请${project.invite_identity_user || 1}位好友`;
            }
          } else if (project.describe.includes('贵重金属')) {
            // 查找有色金属项目
            const colorMetalProject = this.projectList.find(p => p.describe.includes('有色金属'));
            if (!colorMetalProject || colorMetalProject.status !== 3) {
              message = '请先激活有色金属';
            } else {
              message = `请邀请${project.invite_identity_user || 1}位好友`;
            }
          } else if (project.describe.includes('绿色能源')) {
            // 查找贵重金属项目
            const preciousMetalProject = this.projectList.find(p => p.describe.includes('贵重金属'));
            if (!preciousMetalProject || preciousMetalProject.status !== 3) {
              message = '请先激活贵重金属';
            } else {
              message = `请邀请${project.invite_identity_user || 1}位好友`;
            }
          }

          if (message) {
            await this.$dialog.alert({
              title: '提示',
              message,
              theme: 'round-button',
              confirmButtonText: '确定',
            });
            return;
          }
        }

        // 如果是确认状态(status === 2)，直接调用支付确认
        if (project.status === 2) {
          if (!this.projectInputs[project.id]) {
            await this.$dialog.alert({
              title: '提示',
              message: '请输入金额',
              theme: 'round-button',
              confirmButtonText: '确定',
            });
            return;
          }

          this.currentProject = project;
          await this.handlePayConfirm({payType: 8});
        }
    },
    // 处理支付确认
    async handlePayConfirm({ payType }) {
      try {
        const params = {
          source: 11, // 资环钱包
          pay_type: payType,
          project_id: this.currentProject.id,
          amount: this.projectInputs[this.currentProject.id]
        };

        const res = await axios.post(api.balancePay, params);

        // 清空当前项目的输入
        this.projectInputs[this.currentProject.id] = '';
        this.currentProject = null;

        // 余额支付
        if (payType === 8) {
          await this.$dialog.alert({
            title: '提示',
            message: '激活成功！',
            theme: 'round-button',
            confirmButtonText: '确定',
            confirmButtonColor: '#F52735'
          });

          // 刷新所有数据
          await Promise.all([
            this.getProjectList(),
            this.getInvestmentData()
          ]);
          return;
        }

        // 其他支付方式
        if (res.data?.payUrl) {
          window.location.href = res.data.payUrl;
        }

      } catch (err) {
        this.$toast(err.msg || '支付失败');
      }
    },
  }
}
</script>

<style scoped lang="less">
.custom-loading {
  .van-loading {
    .van-loading__spinner {
      color: #F52735;
    }
    .van-loading__text {
      color: #F52735;
    }
  }
}
</style>
