<template>
  <div class="login_box">
    <img
      src="../assets/img/login/bg_login.png"
      alt="bg"
      class="login-bg fixed inset-0 w-full h-full object-cover"
    />

    <div class="customer-service fixed top-0 right-0 m-2 flex flex-col items-center cursor-pointer bg-black/20 rounded-lg p-2 w-16" @click="handleCustomerService">
      <img src="@/assets/img/index/ic_kefu.png" class="w-10">
      <span class="text-xs text-white mt-1">在线客服</span>
    </div>

    <div class="flex flex-col mt-[150px]">
      <div class="flex mb-8 px-8">
        <img src="../assets/img/login/party_logo.png" alt="logo" class="w-8 h-8 mr-2"/>
        <span class="text-xl font-bold" style="color: rgba(0, 0, 0, 0.90)">中国资源循环集团</span>
      </div>

      <div class="w-full px-8">
        <div class="space-y-6">
          <input
            class="w-full h-12 px-4 bg-transparent border-b border-white text-[#686868] focus:outline-none placeholder-[#686868]"
            v-model="mobile"
            maxlength="11"
            placeholder="请输入手机号">

          <input
            class="w-full h-12 px-4 bg-transparent border-b border-white text-[#686868] focus:outline-none placeholder-[#686868]"
            v-model="password"
            type="password"
            placeholder="请输入密码">
        </div>

        <button
          class="w-full h-12 mt-10 bg-[#FF4D4F] text-white rounded-full text-lg font-medium"
          @click="onLogin">
          立即登录
        </button>

        <div
          class="w-full text-center mt-4 text-[#686868]"
          @click="jumpToPage('register')">
          还没有账号？快速注册
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import {isNullOrEmpty} from "../utils";
import UserStorage from "@/utils/user_storage";
import {mapState, mapActions} from "vuex";

export default {
  name: "Login",
  computed: {
    ...mapState("systemInfo", ["systemInfo"]),
  },
  data() {
    return {
      mobile: "",
      password: "",
      loading: false,
    };
  },
  mounted() {
    this.loginByToken();
    this.getSystemInfo();
  },
  methods: {
    ...mapActions("systemInfo", ["getSystemInfo"]),
    loginByToken() {
      try {
        const token = this.$route.query.token;
        if (token) {
          UserStorage.setToken(token);
          window.token = token;
          this.$router.replace({name: "home"});
        }
      } catch (e) {
        console.log(e);
      }
    },
    onLogin() {
      if (isNullOrEmpty(this.mobile)) {
        this.$toast("请输入手机号");
        return;
      }
      if (isNullOrEmpty(this.password)) {
        this.$toast("请输入密码");
        return;
      }
      this.$toast.loading({
        message: "登录中...",
        forbidClick: true,
      });

      const formData = new FormData();
      formData.append('account', this.mobile);
      formData.append('password', this.password);
      axios
          .post(api.login, formData)
          .then((res) => {
            this.$toast.clear();
            console.log("登录成功", res);
            UserStorage.setToken(res.data.token);
            window.token = res.data.token;
            this.$router.replace({name: "home"});
          })
          .catch((e) => {
            console.log('登录失败', e)
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    },
    jumpToPage(name) {
      this.$router.push({name});
    },
    handleCustomerService() {
      if (this.systemInfo?.customerService) {
        window.location.href = this.systemInfo.customerService;
      }
    }
  },
};
</script>
<style scoped lang="less">
.login_box {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: transparent !important;

  .login-bg {
    z-index: 0;
  }

  > div:not(.customer-service) {
    position: relative;
    z-index: 1;
  }

  input::placeholder {
    color: #686868;
  }

  div[class*="text-center"] {
    cursor: pointer;
    &:active {
      opacity: 0.8;
    }
  }
}
</style>
