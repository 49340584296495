<template>
  <div class="h-full w-full">
    <div class="overflow-y-auto w-full relative page-content-2">
      <img src="@/assets/img/home/home_bg.png" class="w-full" alt="bg_top"/>
      <div class="absolute top-12 left-0 right-0 px-4">
        <div class="text-white text-xl font-bold mb-4">中国资源循环集团</div>
         <HomeVideo/>
      </div>
      <div class="content-wrapper mt-10">
        <HomeMenu></HomeMenu>
        <News></News>
      </div>
    </div>
    <popup v-for="(notice, index) in notices"
           :key="index"
           v-model="notice.show"
           position="center"
           :closeable="false">
      <div class="bg-white rounded-[20px] overflow-hidden">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center py-4">
          <img src="@/assets/img/index/ic_notice.png" alt="" style="width: 25px;height: 25px;margin-right: 10px;">
          公告消息
        </div>
        <div class="bg-white px-4 py-3 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="notice.content" class="text-[16px]"></p>
        </div>
        <div class="py-6 text-[16px] flex justify-center">
          <div @click="closeDialog(index)" class="bg-primary text-white px-12 py-2.5 rounded-full cursor-pointer hover:opacity-90">
            我知道了
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import HomeMenu from "@/components/home/HomeMenu";
import News from "@/components/home/News";
import {mapActions, mapState} from "vuex";
import HomeVideo from "@/components/home/HomeVideo";
import {Popup,} from "vant";
import api from "@/net/api";
import axios from "@/net/axios";

export default {
  name: "index",
  components: { HomeVideo, HomeMenu, News, Popup},
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title;
    }
  },
  data() {
    return {
      notices: [],
      currentNoticeIndex: 0
    };
  },
  created() {
    this.getNoticeList();
  },
  mounted() {
    console.log("首页mounted");
    this.getUserInfo();
    this.getSystemInfo();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),

    closeDialog(index) {
      this.notices[index].show = false;
      this.currentNoticeIndex++;
      if (this.currentNoticeIndex < this.notices.length) {
        setTimeout(() => {
          this.showNextNotice();
        }, 300);
      }
    },

    getNoticeList() {
      axios
        .get(api.newsList, { params: { type: 1, index_status: 1 } })
        .then((res) => {
          if (res.data.length > 0) {
            this.notices = res.data.map(notice => ({
              ...notice,
              show: false
            }));
            this.showNextNotice();
          }
        })
        .catch(() => {
          console.log('获取公告失败');
        });
    },

    showNextNotice() {
      if (this.currentNoticeIndex < this.notices.length) {
        this.notices[this.currentNoticeIndex].show = true;
      }
    },

  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.bg-top {
  background-image: linear-gradient(180deg, #7496FF 0%, rgba(116, 150, 255, 0.00) 100%);
}

.bottom {
  background-image: linear-gradient(113deg, #4B49FF 0%, #7D9AFF 100%);
  border-radius: 4px;
}

.notice-title {
  width: 320px;
  height: 67px;
}

.item {
  box-shadow: 0px 0px 11.6px 0px rgba(193, 193, 193, 0.25);
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  color: #333;
  font-size: 16px;
}

.content-wrapper {
  position: relative;
  z-index: 1;
}

.banner-img {
  margin-bottom: -40px;
}

:deep(.van-popup) {
  border-radius: 20px;
  overflow: hidden;
}

.notice-title {
  padding: 16px 0;
  border-bottom: 1px solid #f5f5f5;
}
</style>
