var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"h-screen flex flex-col bg-[#a11717]"},[_c('van-overlay',{staticClass:"flex items-center justify-center",attrs:{"show":_vm.pageLoading}},[_c('van-loading',{attrs:{"type":"spinner","color":"#fff","size":"36","vertical":""}},[_vm._v("加载中...")])],1),_vm._m(0),_c('div',{staticClass:"flex-1 overflow-y-auto"},[_c('div',{staticClass:"relative",style:({ height: _vm.topImageHeight + 'px' })},[_c('div',{staticClass:"absolute inset-0 w-full bg-cover bg-center",style:({backgroundImage: 'url(' + require('@/assets/img/partner_bg_top.png') + ')'})}),_c('div',{staticClass:"relative px-4 pt-8"},[_vm._m(1),_c('div',{staticClass:"text-center mb-28"},[_c('button',{class:[
                      'px-12 py-2 rounded-full text-lg font-bold',
                      _vm.userInfo.partner_level === 1
                        ? 'bg-gray-400 text-white cursor-not-allowed'
                        : 'bg-white text-red-600'
                    ],attrs:{"disabled":_vm.userInfo.partner_level === 1},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.userInfo.partner_level === 1 ? '已领取' : '申请')+" ")])])])]),_c('div',{staticClass:"relative -mt-[2px]",style:({ height: _vm.bottomImageHeight + 'px' })},[_c('div',{staticClass:"absolute inset-0 w-full bg-cover bg-center",style:({backgroundImage: 'url(' + require('@/assets/img/partner_bg_bottom.png') + ')'})}),_c('div',{staticClass:"relative px-4"},[_c('div',{staticClass:"px-8 pt-2"},[_c('div',{staticClass:"text-[#FF5A46] text-2xl font-bold mb-4 text-center"},[_vm._v("合伙人福利领取")]),_c('div',{staticClass:"space-y-4"},_vm._l((_vm.benefitsList),function(item){return _c('div',{key:item.id,staticClass:"flex justify-between items-center"},[_c('span',{staticClass:"benefit-text"},[_vm._v(_vm._s(item.describe))]),_c('button',{class:[
                    'receive-btn',
                    item.status === 2 ? 'bg-gray-400 text-white cursor-not-allowed' : ''
                  ],attrs:{"disabled":item.status === 2},on:{"click":function($event){return _vm.handleClaimBenefit(item)}}},[_vm._v(" "+_vm._s(item.status === 2 ? '已领取' : '领取')+" ")])])}),0)])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-[44px] flex-shrink-0 flex items-center justify-center px-4",staticStyle:{"background":"radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%)"}},[_c('span',{staticClass:"text-white text-lg"},[_vm._v("合伙人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-8"},[_c('div',{staticClass:"text-yellow-400 text-2xl font-bold mb-2"},[_vm._v(" 中国资源循环集团有限公司 ")]),_c('div',{staticClass:"text-yellow-400 text-xl"},[_vm._v(" 申请成为合伙人免费领取 ")])])
}]

export { render, staticRenderFns }