<template>
  <div class="login_box">
    <img
      src="../assets/img/login/bg_login.png"
      alt="bg"
      class="login-bg fixed inset-0 w-full h-full object-cover"
    />

    <div class="flex flex-col mt-[100px]">
      <div class="flex mb-8 px-8">
        <img src="../assets/img/login/party_logo.png" alt="logo" class="w-8 h-8 mr-2"/>
        <span class="text-xl font-bold" style="color: rgba(0, 0, 0, 0.90)">中国资源循环集团</span>
      </div>

      <div class="w-full px-8">
        <div class="space-y-6">
          <input
            class="w-full h-12 px-4 bg-transparent border-b border-white text-[#686868] focus:outline-none placeholder-[#686868]"
            v-model="mobile"
            maxlength="11"
            placeholder="请输入手机号">

          <input
            class="w-full h-12 px-4 bg-transparent border-b border-white text-[#686868] focus:outline-none placeholder-[#686868]"
            v-model="invitaCode"
            placeholder="请输入推荐码">

          <div class="relative w-full h-12 bg-transparent border-b border-white flex items-center"
               @click="showProvincePicker = true">
            <input
              class="w-full h-full px-4 bg-transparent text-[#686868] focus:outline-none placeholder-[#686868]"
              :value="selectedProvince"
              readonly
              placeholder="请选择省份">
            <van-icon name="arrow-down" class="absolute right-4" size="14" color="#686868" />
          </div>

          <input
            class="w-full h-12 px-4 bg-transparent border-b border-white text-[#686868] focus:outline-none placeholder-[#686868]"
            v-model="password"
            type="password"
            placeholder="请输入密码">

          <input
            class="w-full h-12 px-4 bg-transparent border-b border-white text-[#686868] focus:outline-none placeholder-[#686868]"
            v-model="password1"
            type="password"
            placeholder="请确认密码">
        </div>

        <button
          class="w-full h-12 mt-10 bg-[#FF4D4F] text-white rounded-full text-lg font-medium"
          @click="onRegister">
          立即注册
        </button>

        <div
          class="w-full text-center mt-4 text-[#686868]"
          @click="jumpToPage('login')">
          已有账号？去登录
        </div>
      </div>
    </div>

    <van-popup 
      v-model="showProvincePicker" 
      position="bottom"
      round
      :style="{ maxHeight: '50%' }"
      :lock-scroll="false"
      class="province-picker"
      teleport="body"
    >
      <van-picker
        show-toolbar
        title="选择省份"
        :columns="provinces.filter(p => p !== '')"
        @confirm="onProvinceConfirm"
        @cancel="showProvincePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import {isNullOrEmpty} from "../utils";
import UserStorage from "@/utils/user_storage";
import { Picker, Popup, Icon } from 'vant';
import chinaJson from '@/assets/china.json'

export default {
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
  },
  data() {
    return {
      mobile: "",
      password: "",
      password1: "",
      invitaCode: "",
      showProvincePicker: false,
      selectedProvince: '',
      provinces: [],  // 将从china.json中获取
    };
  },
  mounted() {
    console.log("邀请码", this.$route.query.code);
    this.invitaCode = this.$route.query.code || "";
    
    // 从 chinaJson 中获取省份列表并格式化
    this.provinces = chinaJson.features
      .map(feature => this.formatProvinceName(feature.properties.name))
      .filter(name => name); // 过滤掉空值
  },
  methods: {
    jumpToPage(name) {
      this.$router.push({name});
    },
    onRegister() {
      if (isNullOrEmpty(this.mobile)) {
        this.$toast("请输入手机号");
        return;
      }
      if (isNullOrEmpty(this.password)) {
        this.$toast("请输入密码");
        return;
      }
      if (isNullOrEmpty(this.password1)) {
        this.$toast("请再次输入密码");
        return;
      }
      if (this.password !== this.password1) {
        this.$toast("两次密码输入不一致");
        return;
      }
      if (isNullOrEmpty(this.invitaCode)) {
        this.$toast("请输入推荐码");
        return;
      }
      if (isNullOrEmpty(this.selectedProvince)) {
        this.$toast("请选择省份");
        return;
      }
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });


      const formData = new FormData();
      formData.append('account', this.mobile);
      formData.append('code', this.invitaCode);
      formData.append('password', this.password);
      formData.append('re_password', this.password1);
      formData.append('province', this.selectedProvince);
      axios
          .post(api.register, formData)
          .then((res) => {
            this.$toast.clear();
            console.log("注册成功", res);
            UserStorage.setToken(res.data.token);
            window.token = res.data.token;
            this.$router.replace({name: "home"});
          })
          .catch((e) => {
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    },

    onProvinceConfirm(value) {
      console.log('选中的省份:', value);
      this.selectedProvince = value;
      this.showProvincePicker = false;
    },

    formatProvinceName(name) {
      const suffixes = ['省', '市', '自治区', '特别行政区'];
      // 如果已经包含后缀,直接返回
      if(suffixes.some(suffix => name.endsWith(suffix))) {
        return name;
      }
      // 特殊处理自治区
      if(['内蒙古', '广西', '西藏', '宁夏', '新疆'].includes(name)) {
        return name + '自治区';
      }
      // 特处理直辖市
      if(['北京', '天津', '上海', '重庆'].includes(name)) {
        return name + '市';
      }
      // 其他添加省字
      return name + '省';
    },

  },
};
</script>
<style scoped lang="less">
.login_box {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: transparent !important;

  .login-bg {
    z-index: 0;
  }

  > div {
    position: relative;
    z-index: 1;
  }

  input::placeholder {
    color: #686868;
  }

  div[class*="text-center"] {
    cursor: pointer;
    &:active {
      opacity: 0.8;
    }
  }
}

:deep(.province-picker) {
  position: fixed !important;
  
  &.van-popup--bottom {
    max-height: 50vh;
  }

  .van-picker {
    width: 100%;
    height: 100%;
    
    .van-picker__toolbar {
      height: 44px;
    }
    
    .van-picker-column__item {
      padding: 0 16px;
      font-size: 16px;
    }

    .van-picker-column {
      height: calc(100% - 44px);
    }
  }
}
</style>
