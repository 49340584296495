<template>
  <div>
    <div class="h-screen flex flex-col bg-[#a11717]">
      <!-- loading遮罩 -->
      <van-overlay :show="pageLoading" class="flex items-center justify-center">
        <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
      </van-overlay>

      <!-- 固定标题栏 -->
      <div class="h-[44px] flex-shrink-0 flex items-center justify-center px-4"
           style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);">
        <span class="text-white text-lg">合伙人</span>
      </div>

      <!-- 内容区域 -->
      <div class="flex-1 overflow-y-auto">
        <!-- 上半部分 - 公司信息和申请按钮 -->
        <div class="relative" :style="{ height: topImageHeight + 'px' }">
          <div class="absolute inset-0 w-full bg-cover bg-center"
               :style="{backgroundImage: 'url(' + require('@/assets/img/partner_bg_top.png') + ')'}">
          </div>
          <div class="relative px-4 pt-8">
            <!-- 公司名称和副标题 -->
            <div class="text-center mb-8">
              <div class="text-yellow-400 text-2xl font-bold mb-2">
                中国资源循环集团有限公司
              </div>
              <div class="text-yellow-400 text-xl">
                申请成为合伙人免费领取
              </div>
            </div>
            <!-- 申请按钮 -->
            <div class="text-center mb-28">
              <button @click="handleSubmit"
                      :disabled="userInfo.partner_level === 1"
                      :class="[
                        'px-12 py-2 rounded-full text-lg font-bold',
                        userInfo.partner_level === 1
                          ? 'bg-gray-400 text-white cursor-not-allowed'
                          : 'bg-white text-red-600'
                      ]">
                {{ userInfo.partner_level === 1 ? '已领取' : '申请' }}
              </button>
            </div>
          </div>
        </div>

        <!-- 下半部分 - 福利列表 -->
        <div class="relative -mt-[2px]" :style="{ height: bottomImageHeight + 'px' }">
          <div class="absolute inset-0 w-full bg-cover bg-center"
               :style="{backgroundImage: 'url(' + require('@/assets/img/partner_bg_bottom.png') + ')'}">
          </div>
          <div class="relative px-4">
            <!-- 福利列表 -->
            <div class="px-8 pt-2">
              <div class="text-[#FF5A46] text-2xl font-bold mb-4 text-center">合伙人福利领取</div>
              <div class="space-y-4">
                <div v-for="item in benefitsList" :key="item.id" class="flex justify-between items-center">
                  <span class="benefit-text">{{ item.describe }}</span>
                  <button
                    @click="handleClaimBenefit(item)"
                    :disabled="item.status === 2"
                    :class="[
                      'receive-btn',
                      item.status === 2 ? 'bg-gray-400 text-white cursor-not-allowed' : ''
                    ]"
                  >
                    {{ item.status === 2 ? '已领取' : '领取' }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Loading, Overlay, Toast, Dialog } from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {mapActions, mapState} from 'vuex';

export default {
  components: {
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
  },

  data() {
    return {
      pageLoading: false,
      topImageHeight: 0,
      bottomImageHeight: 0,
      benefitsList: [],
    }
  },

  computed: {
    ...mapState("user", ["userInfo"]),
  },

  created() {
    this.$toast = Toast;
    this.$dialog = Dialog;
  },

  mounted() {
    this.getUserInfo();
    this.getBenefitsList();
    this.calculateImageHeights();
    // 监听窗口大小变化
    window.addEventListener('resize', this.calculateImageHeights);
  },

  beforeDestroy() {
    // 移除监听器
    window.removeEventListener('resize', this.calculateImageHeights);
  },

  methods: {
    ...mapActions("user", ["getUserInfo"]),
    calculateImageHeights() {
      // 获取窗口宽度
      const windowWidth = window.innerWidth;

      // 加载顶部图片并计算高度
      const topImage = new Image();
      topImage.src = require('@/assets/img/partner_bg_top.png');
      topImage.onload = () => {
        this.topImageHeight = (windowWidth * topImage.height) / topImage.width;
      };

      // 加载底部图片并计算高度
      const bottomImage = new Image();
      bottomImage.src = require('@/assets/img/partner_bg_bottom.png');
      bottomImage.onload = () => {
        this.bottomImageHeight = (windowWidth * bottomImage.height) / bottomImage.width;
      };
    },
    async handleSubmit() {
      console.log('handleSubmit',this.userInfo.partner_level)
      if (this.userInfo.partner_level !== 0) return;

      try {
        this.pageLoading = true;
        await axios.post(api.activePartnerInvite);
        await this.getUserInfo();
        await Dialog.alert({
          message: '申请成功！',
        });
      } catch (error) {
        await Dialog.alert({
          message: error.msg || '申请失败，请稍后重试',
        });
      } finally {
        this.pageLoading = false;
      }
    },
    async getBenefitsList() {
      try {
        this.pageLoading = true;
        const response = await axios.get(api.partnerInviteList);
        this.benefitsList = response.data.inviteConfigList || [];
      } catch (error) {
        await Dialog.alert({
          message: error.msg || '获取福利列表失败',
        });
      } finally {
        this.pageLoading = false;
      }
    },

    async handleClaimBenefit(item) {
      if (item.status !== 1) return;

      try {
        this.pageLoading = true;
        await axios.post(api.getPartnerInviteAward, { id: item.id });
        await this.getBenefitsList(); // 更新列表数据
        await Dialog.alert({
          message: '领取成功！',
        });
      } catch (error) {
        await Dialog.alert({
          message: error.msg || '领取失败，请稍后重试',
        });
      } finally {
        this.pageLoading = false;
      }
    },
  }
};
</script>

<style scoped>
.benefit-text {
  @apply flex-1 mr-4 bg-white text-[#515151] rounded-[5px] text-center text-[12px] leading-[40px];
  font-family: "PingFang SC";
}

.receive-btn {
  @apply rounded-[5px] px-4 py-1 text-white;
  &:not(:disabled) {
    background: linear-gradient(180deg, #FF5A46 0%, #F62836 100%);
  }
}
</style>
