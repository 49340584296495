<template>
  <div class="news-container bg-white mt-2">
    <div class="news-header">
      <div class="text-lg font-bold mb-3">热点新闻</div>
      <div class="news-tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['tab-item', { active: currentTab === index }]"
          @click="switchTab(index)"
        >
          {{ tab }}
        </div>
      </div>
    </div>

    <div class="news-list">
      <div v-for="(item, index) in newsList"
           :key="index"
           @click="jumpToPage(item.id)"
           :class="['news-item', { 'border-b': index !== newsList.length - 1 }]">
        <div class="news-title-wrapper">
          <span class="dot"></span>
          <span class="title-text">{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
export default {
  data() {
    return {
      currentTab: 0,
      tabs: ['媒体新闻', '党建风采', '国资动态'],
      typeMap: {
        0: 3,  // 媒体新闻
        1: 5,  // 党建风采
        2: 4   // 国资动态
      },
      newsList: []
    };
  },
  mounted () {
    this.getNewsList();
  },
  methods: {
    switchTab(index) {
      this.currentTab = index;
      this.getNewsList();
    },
    jumpToPage(id) {
      this.$router.push({
        name: 'newsDetails',
        params: {
          id,
          type:1
        }
      })
    },

    getNewsList() {
      axios
        .get(api.newsList, {
          params: {
            type: this.typeMap[this.currentTab],  // 使用映射关系获取正确的type值
            index_status: 0
          }
        })
        .then((res) => {
          this.newsList = res.data;
        })
        .catch((err) => {
          err.msg && this.$toast(err.msg);
        });
    },
  },
};
</script>

<style scoped lang="less">
.news-container {
  padding: 16px;
}

.news-tabs {
  display: flex;
  margin-top: 12px;
  border-bottom: 1px solid #f0f0f0;
  white-space: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.tab-item {
  padding: 8px 16px;
  margin-right: 24px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;

  &.active {
    color: #F00;
    font-weight: 500;

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 2px;
      background: #F00;
    }
  }
}

.news-item {
  padding: 12px 0;
  cursor: pointer;

  &.border-b {
    border-bottom: 1px solid #f5f5f5;
  }
}

.news-title-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  .dot {
    width: 4px;
    height: 4px;
    background-color: #333;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .title-text {
    font-size: 14px;
    color: #333;
    line-height: 1.4;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0; // 确保flex子元素可以正确收缩
  }
}
</style>
