<template>
  <div class="page-container h-screen text-black">
    <nav-bar
      :title="data.title?.endsWith('提现') ? data.title : data.title?.concat('提现')"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      :safe-area-inset-top="true"
      class="w-full text-white"
      :border="false"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="text-center">
          <p class="text-sm py-2 rounded w-auto" style="background: #DCDCDC;color: #CF0010;">
            提现如果遇到高峰期，可能会延迟到账
          </p>
          <h1 class="font-bold mt-4" style="color: #CF0010;font-size:40px">{{ balance }}</h1>
          <p style="font-size: 20px;margin-bottom: 20px">总金额(元)</p>
        </div>

        <form @submit.prevent="onSubmit" class="bg-white rounded-t-lg p-4 mt-6 w-full">
          <h2 class="text-black text-lg font-bold mb-4">申请提现</h2>
          <div class="item-box">
            <div class="item">
              <span>￥</span>
              <input
                type="text"
                placeholder="请输入提现金额"
                maxlength="11"
                v-model="amount"
              />
            </div>
            <div v-if="data.type === 'invite_award'" class="daily-limit">
              每日提现额度: {{ data.daily_limit }}元
            </div>
          </div>

          <Button 
            round 
            block 
            type="info" 
            color="#CF0010" 
            native-type="submit" 
            class="w-full"
            style="margin-bottom: 36px;font-size: 18px"
          >
            立即提现
          </Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Button, NavBar } from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import { WithdrawalType } from "@/config";
import { isNullOrEmpty } from "@/utils";

export default {
  components: { NavBar, Button },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  data() {
    return {
      data: this.$route.query,
      WithdrawalType,
      amount: "",
      balance: this.$route.query.amount || 0,
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit(event) {
      event.preventDefault();
      const { amount } = this;
      if (isNullOrEmpty(amount)) {
        this.$toast("请输入提现数额");
        return
      }
      if (parseFloat(amount) > parseFloat(this.balance)) {
        this.$toast("提现金额不能大于可用余额");
        return;
      }
      
      const loading = this.$toast.loading({
        message: '提交中...',
        forbidClick: true,
      });
      
      const params = {
        num: amount,
        coin_type: this.data.type,
      };

      if (this.data.type === 'reborn_invite_award') {
        params.to_coin_type = 'zihuan_wallet';
      }
      
      axios.post(api.userWithdrawal, params).then((res) => {
        loading.clear();
        this.$dialog.alert({
          message: "提现成功",
          confirmButtonText: "确定",
        }).then(() => {
          this.onClickLeft();
        }); 
      }).catch((err) => {
        loading.clear();
        err.msg && this.$toast(err.msg);
      });
    },
  },
}
</script>

<style scoped lang="less">
.page-container {
  background-image: url("../../assets/img/index/bg_top3.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.item-box {
  margin-top: 40px;
  margin-bottom: 36px;

  .item {
    height: 55px;
    margin-top: 22px;
    display: flex;
    border: grey solid 1px;
    border-radius: 10px;
    align-items: center;
    padding: 0px 22px;

    span {
      width: 8%;
      background: none;
      font-size: 30px;
      font-weight: bold;
    }

    input {
      width: 97%;
      padding-left: 3%;
      height: 100%;
      background: none;
      font-size: 20px;
      font-weight: bold;
      color: #333;

      &::placeholder {
        color: rgba(128, 128, 128, 0.5);
      }
    }
  }
}

.daily-limit {
  margin-top: 8px;
  color: #999;
  font-size: 14px;
  padding-left: 22px;
}
</style>
