<template>
  <div class="flex flex-col h-screen">
    <!-- loading遮罩 -->
    <van-overlay :show="pageLoading" class="flex items-center justify-center">
      <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
    </van-overlay>

    <!-- 标题栏 -->
    <div class="h-[44px] flex items-center px-4 relative" style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);">
      <van-icon name="arrow-left" class="text-white" @click="onClickLeft" />
      <span class="text-white text-lg absolute left-1/2 -translate-x-1/2">激活省份</span>
    </div>

    <!-- 内容区域 -->
    <div class="flex-1 overflow-y-auto bg-[#B1130F] pb-16">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        head-height="50"
        loading-text="加载中..."
        pulling-text=""
        loosing-text=""
        success-text=""
        :loading-icon="loadingIcon"
        class="custom-loading"
      >
        <!-- 地图背景 -->
        <div class="py-3">
          <div class="h-[200px]" ref="mapChart"></div>
        </div>

        <!-- 新增激活统计和奖励说明 -->
        <div class="px-4 mb-4">
          <div class="bg-white rounded-[10px] p-4">
            <div class="flex items-center justify-between mb-3">
              <span class="text-[#333] text-base">已激活省份数量</span>
              <span class="text-[#F52735] text-xl font-medium">{{ activatedCount }}个</span>
            </div>
            <div class="text-sm text-[#666] leading-6">
              <div class="mb-2">激活<span class="text-[#F52735]">3</span>个省份:</div>
              <div class="pl-4 mb-1">每日分红</div>
              <div class="pl-4 mb-2">再生资源投资金10000元</div>
              <div class="pl-4">环保金5000元</div>

              <div class="mb-2">激活<span class="text-[#F52735]">5</span>个省份:</div>
              <div class="pl-4 mb-1">每日分红</div>
              <div class="pl-4 mb-2">再生资源投资金30000元</div>
              <div class="pl-4">环保金15000元</div>

              <div class="mb-2">激活<span class="text-[#F52735]">10</span>个省份:</div>
              <div class="pl-4 mb-1">每日分红</div>
              <div class="pl-4 mb-2">再生资源投资金60000元</div>
              <div class="pl-4">环保金30000元</div>

              <div class="mb-2">激活<span class="text-[#F52735]">20</span>个省份:</div>
              <div class="pl-4 mb-1">每日分红</div>
              <div class="pl-4 mb-2">再生资源投资金200000元</div>
              <div class="pl-4">环保金100000元</div>

              <div class="mb-2">激活<span class="text-[#F52735]">34</span>个省份:</div>
              <div class="pl-4 mb-1">每日分红</div>
              <div class="pl-4 mb-2">再生资源投资金600000元</div>
              <div class="pl-4">环保金400000元</div>
            </div>
          </div>
        </div>

        <!-- 选择区域 -->
        <div class="px-4 py-3">
          <div class="text-white text-lg mb-2">所在地区</div>
          <div class="bg-white rounded-[10px] p-3">
            <div class="border border-[#ECA9A9] rounded-[10px] p-3">
              <div class="flex justify-between items-center">
                <div class="flex-1">
                  <div class="text-[14px] text-[#484848] font-normal mb-2 text-center">省份</div>
                  <div class="relative w-full h-[32px] bg-[#F2F2F2] rounded-[3px] flex items-center justify-center text-[13px] text-[#484848]"
                       @click="showProvince">
                    <span>{{ selectedProvince || '请选择' }}</span>
                    <van-icon name="arrow-down" class="absolute right-2" size="14" color="#484848" />
                  </div>
                </div>
              </div>
              <div class="flex gap-3 mt-4">
                <button class="flex-1 h-[40px] border border-[#F34947] text-[#F34947] rounded text-base font-medium" @click="onActivate">激活</button>
                <button class="flex-1 h-[40px] bg-[#F34947] text-white rounded text-base font-medium" @click="jumpToDetail">明细</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Picker组件 -->
        <van-popup v-model="showProvincePicker" position="bottom" round>
          <van-picker
            show-toolbar
            :columns="provinces"
            :title="'选择省份'"
            :confirm-button-text="'确认'"
            :cancel-button-text="'取消'"
            @confirm="onProvinceConfirm"
            @cancel="showProvincePicker = false"
          />
        </van-popup>

        <!-- 列表区域 -->
        <div class="mt-4 px-4">
          <div class="bg-white rounded-[10px]">
            <!-- 表头 -->
            <div class="flex text-[14px] h-[44px] items-center bg-[#F6F6F6]">
              <div class="flex-1 text-center text-[#666]">姓名</div>
              <div class="flex-1 text-center text-[#666]">电话</div>
              <div class="flex-1 text-center text-[#666]">激活地区</div>
              <div class="flex-1 text-center text-[#666]">获得收益</div>
            </div>

            <!-- 滚动列表容器 -->
            <div class="scroll-container relative">
              <div
                class="scroll-list absolute w-full"
                :style="{ transform: `translateY(${scrollTop}px)` }"
              >
                <template v-if="displayList && displayList.length > 0">
                  <div
                    v-for="(item, index) in displayList"
                    :key="index"
                    class="list-item"
                  >
                    <div class="flex text-[14px] h-[50px] items-center">
                      <div class="flex-1 text-center text-[#333]">{{item.name}}</div>
                      <div class="flex-1 text-center text-[#333]">{{item.phone}}</div>
                      <div class="flex-1 text-center text-[#333]">{{item.area}}</div>
                      <div class="flex-1 text-center text-[#F52735] font-medium">{{item.income}}万</div>
                    </div>
                    <div class="h-[1px] bg-[#EEEEEE]"></div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
// 导入相关依赖
import * as echarts from 'echarts'
import chinaJson from '@/assets/china.json'
import giftData from '@/assets/user_gifts.json'
import {  Popup, Picker, Icon,  PullRefresh, Loading, Overlay, List } from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import { mapState, mapActions } from "vuex";

export default {
  name: "InviteUser",
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Icon.name]: Icon,
    [PullRefresh.name]: PullRefresh,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    [List.name]: List,
  },
  data() {
    return {
      pageLoading: true,
      mapChart: null,
      mapData: [],
      showProvincePicker: false,
      selectedProvince: '',
      provinces: [],
      activatedList: [],
      displayList: [],
      refreshing: false,
      loadingIcon: 'loading',
      resizeObserver: null,
      scrollTop: 0,
      isScrolling: false,
      scrollInterval: null,
      itemHeight: 51, // 每个列表项的高度（50px + 1px border）
    };
  },

  computed: {
    ...mapState("user", ["userInfo"]),
    // 添加激活省份数量计算
    activatedCount() {
      return this.mapData.filter(item =>
        item.itemStyle?.normal?.areaColor === '#472929'
      ).length;
    }
  },

  created() {
    // 在组件创建时加载数据
    this.initData();
  },

  beforeDestroy() {
    // 清理定时器和观察器
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },

  methods: {
    ...mapActions("user", ["getUserInfo"]),

    async initData() {
      try {
        this.pageLoading = true;
        echarts.registerMap('china', chinaJson);
        await this.getUserInfo();
        await this.getProvinceList();
        await this.loadActivatedList();

        this.$nextTick(() => {
          this.initMap();
          this.resizeObserver = new ResizeObserver(() => {
            this.mapChart && this.mapChart.resize();
          });
          if (this.$refs.mapChart) {
            this.resizeObserver.observe(this.$refs.mapChart);
          }
        });
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        this.pageLoading = false;
      }
    },

    initMap() {
      if (!this.$refs.mapChart) return;

      try {
        this.mapChart = echarts.init(this.$refs.mapChart, {
          backgroundColor: 'transparent',
          renderer: 'canvas'
        })

        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}'
          },
          series: [{
            name: '中国',
            type: 'map',
            map: 'china',
            roam: false,
            aspectScale: 0.90,
            layoutCenter: ['50%', '74%'],
            layoutSize: '160%',
            data: this.mapData,
            emphasis: {
              label: {
                show: true
              },
              itemStyle: {
                areaColor: '#472929'
              }
            }
          }]
        }

        this.mapChart.setOption(option)
      } catch (error) {
        console.error('Map initialization error:', error)
      }
    },

    jumpToDetail() {
      this.$router.push({
        name: "fundDetail",
        query: {
          type:'province_income',
        },
      });
    },

    formatProvinceName(name) {
      const suffixes = ['省', '市', '自治区', '特别行政区'];
      if(suffixes.some(suffix => name.endsWith(suffix))) {
        return name;
      }
      if(['内蒙古', '广西', '西藏', '宁夏', '新疆'].includes(name)) {
        return name + '自治区';
      }
      if(['北京', '天津', '上海', '重庆'].includes(name)) {
        return name + '市';
      }
      return name + '省';
    },

    // 获取省份列表
    async getProvinceList() {
      try {
        const response = await axios.get(api.receiveList, { params: { type: 1 }})
        if(response.data?.provinceList) {
          const allProvinces = chinaJson.features.map(feature => feature.properties.name);

          // 检查用户省份是否已激活
          const userProvince = this.userInfo?.province || '';
          const isUserProvinceActivated = response.data.provinceList.includes(userProvince);

          // 如果用户省份未激活，设置为默认选中
          if (userProvince && !isUserProvinceActivated) {
            this.selectedProvince = userProvince;
          }

          // 更新可选择的省份列表 - 过滤掉已激活的省份
          this.provinces = allProvinces.filter(name => {
            return !response.data.provinceList.includes(name);
          });

          // 更新地图数据
          this.mapData = allProvinces.map(name => {
            const isActive = response.data.provinceList.includes(name);
            return {
              name,
              value: 0,
              itemStyle: {
                normal: {
                  areaColor: isActive ? '#472929' : '#2EEA60',
                  borderColor: '#fff',
                  borderWidth: 1
                }
              }
            }
          });

          if(this.mapChart) {
            this.mapChart.setOption({
              series: [{
                data: this.mapData
              }]
            });
          }
        }
      } catch(err) {
        err.msg && this.$toast(err.msg)
      }
    },

    // 加载列表数据
    async loadActivatedList() {
      try {
        console.log('Loading gift data...'); // 调试日志

        // 确保 giftData 已正确导入
        if (!giftData || !Array.isArray(giftData)) {
          console.error('Gift data is not available or not an array');
          return;
        }

        // 处理数据
        const processedData = giftData.map(item => ({
          name: this.maskName(item.real_name || ''),
          phone: this.maskPhone(item.username || ''),
          area: item.province || '',
          income: ((item.gift_coin_num || 0) / 10000).toFixed(2)
        }));

        console.log('Processed data length:', processedData.length); // 调试日志

        // 设置数据
        this.activatedList = processedData;
        // 复制数据用于无缝滚动
        this.displayList = [...processedData, ...processedData];

        console.log('Display list length:', this.displayList.length); // 调试日志

        // 初始化滚动
        this.$nextTick(() => {
          if (this.displayList.length > 0) {
            console.log('Initializing auto scroll...'); // 调试日志
            this.initAutoScroll();
          }
        });
      } catch (error) {
        console.error('Error loading list:', error);
      }
    },

    // 初始化自动滚动
    initAutoScroll() {
      // 清除现有定时器
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
      }

      // 检查是否有数据
      if (!this.displayList.length) {
        console.log('No data to scroll');
        return;
      }

      console.log('Starting auto scroll...'); // 调试日志

      // 重置滚动位置
      this.scrollTop = 0;
      this.isScrolling = false;

      // 设置滚动间隔
      this.scrollInterval = setInterval(() => {
        if (!this.isScrolling) {
          this.isScrolling = true;
          this.scrollTop -= this.itemHeight;

          // 当滚动到第一组数据末尾时，重置位置
          const resetPoint = this.activatedList.length * this.itemHeight;
          if (Math.abs(this.scrollTop) >= resetPoint) {
            console.log('Resetting scroll position...'); // 调试日志
            setTimeout(() => {
              this.isScrolling = false;
              this.scrollTop = 0;
            }, 500);
          } else {
            setTimeout(() => {
              this.isScrolling = false;
            }, 500);
          }
        }
      }, 3000); // 每3秒滚动一次
    },

    // 修改刷新方法
    async onRefresh() {
      try {
        this.refreshing = true;
        await this.loadActivatedList();
      } catch (error) {
        console.error('Refresh error:', error);
      } finally {
        this.refreshing = false;
      }
    },

    // 添加姓名脱敏方法
    maskName(name) {
      if (!name) return '未实名';
      if (name.length === 2) {
        return name.substr(0, 1) + '*';
      }
      return name.substr(0, 1) + '*'.repeat(name.length - 2) + name.substr(-1);
    },

    // 在 methods 中添加手机号脱敏方法
    maskPhone(phone) {
      if (!phone) return '';
      return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    },

    // 添加缺失的方法
    onClickLeft() {
      this.$router.go(-1);
    },

    showProvince() {
      if (!this.provinces.length) {
        this.$toast('暂无可选省份');
        return;
      }
      this.showProvincePicker = true;
    },

    async onActivate() {
      if (!this.selectedProvince) {
        this.$toast('请选择省份');
        return;
      }

      const loading = this.$toast.loading({
        message: '激活中...',
        forbidClick: true,
      });

      try {
        await axios.post(api.activeInvite, {
          province: this.selectedProvince
        });

        this.$toast.success('激活成功');
        this.selectedProvince = '';
        this.showProvincePicker = false;

        // 重新加载数据
        await this.getProvinceList();
        await this.loadActivatedList();

      } catch (err) {
        if (err.code === 430) {
          await this.$dialog.alert({
            message: err.msg || '请先完成实名认证',
            confirmButtonText: '去认证',
            confirmButtonColor: '#F34947',
          });
          this.$router.push({name: 'realNameAuth'});
        } else {
          await this.$dialog.alert({
            message: err.msg || '激活失败',
            confirmButtonText: '确定',
            confirmButtonColor: '#F34947',
          });
        }
      } finally {
        loading.clear();
      }
    },

    onProvinceConfirm(value) {
      this.selectedProvince = value;
      this.showProvincePicker = false;
    },
  },
}
</script>

<style scoped lang="less">
.scroll-container {
  height: 300px;
  overflow: hidden;
  position: relative;
}

.scroll-list {
  position: absolute;
  left: 0;
  right: 0;
  transition: transform 0.5s linear;
  will-change: transform;
}

.list-item {
  background: #fff;
  &:hover {
    background: #f5f5f5;
  }
}

// 其他样式保持不变...
.custom-loading {
  :deep(.van-loading) {
    .van-loading__spinner {
      width: 36px !important;
      height: 36px !important;
    }
  }
}

.van-pull-refresh {
  overflow: visible;
  background: transparent;
}

.van-pull-refresh__track {
  background: transparent;
}

:deep(.van-picker) {
  .van-picker__toolbar {
    background: #F6F6F6;
  }
  .van-picker__confirm {
    color: #F52735;
  }
}

:deep(.van-overlay) {
  background-color: rgba(0, 0, 0, 0.4);
}

:deep(.van-picker-column__item) {
  color: #333333;
}

:deep(.van-picker-column__item--selected) {
  color: #F52735;
  font-weight: 500;
}
</style>
